@media screen and (min-width: 1300px) {
header nav{
        width:100%;
        min-height:4.5rem;
        z-index: 900;
        box-sizing: border-box;
        position:fixed;
        font-family: 'Roboto', sans-serif;
        transition: all 0.7s ease-in-out;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.51);
        background:#fff;
    }

    header nav .logoM{
        display:none;
    }

    header nav button{
        display: none;
    }
    header nav .nav {
        position: relative;
        height:4.5rem;
        transition: all 0.7s ease-in-out;
        vertical-align: top;
        box-sizing: border-box;
        width:1250px;
        margin: 0 auto;
        z-index: 11;
    }

    header nav .nav .menuH {
        text-align: center;
        width:calc(100% - 12rem);
        height:100%;
        display: inline-block;
        vertical-align: top;
    }

    header nav .nav .menuH div{
        display: inline-block;
        position: relative;
        height:100%;
        vertical-align: top;
    }
    header nav .nav .menuH .chev{
        width:1.3rem;
        padding-top: 1rem;
    }
    header nav .nav .menuH .chev div{
        height:45% !important;
        width:1px;
        background: #686868;
        content: ' ' ;
        transform: skew(-30deg);
    }

    header nav .nav .menuH  div .a{
        text-decoration: none;
        text-align: center;
        display: inline-block;
        color: #686868;
        font-size:1.2em;
        font-weight: 500;
        padding:0.2rem 1rem;
        box-sizing: border-box;
        margin-left: 0.4rem;
        height:100%;
        line-height: 4rem;
        transition: all 0.2s ease-in-out;
    }
    header nav .nav .menuH  div i{
        position: absolute;
        top:3rem;
        left:50%;
        transform: translateX(-50%);
        color:#436CF7;
        font-size: 0.8rem;
        transition: all 0.2s ease-in-out;
        opacity: 0;
    }

    header nav .nav .menuH  div:hover i, header nav .nav .menuH  div .activeA i{
        opacity: 1;
    }
    header nav .nav .menuH  div:hover .a, header nav .nav .menuH  div .activeA{
        color: #436CF7;
    }
    header nav .nav .menuH  div .border{
        background:#436CF7;
        width:100%;
        height:0.4rem;
        border-radius: 2px 2px 0 0;
        content: ' ' ;
        position: absolute;
        bottom:0;
        left:0;
        transition: all 0.2s ease-in-out;
        opacity: 0;
    }
    header nav .nav .menuH  div:hover .border, header nav .nav .menuH  div .activeA .border{
        opacity: 1;
    }

    header nav .nav .menuH  .special{
        height:3.5rem;
        width:12rem;
        overflow: hidden;

    }
    header nav .nav .menuH  .special div{
        background: #1048A0;
        height:3.5rem;
        width:100%;
        margin: 0.5rem 0 0 30px;
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
        overflow: hidden;
        position: relative;
        transition: all 0.2s ease-in-out;

    }
    header nav .nav .menuH  .special div:hover{
        background: #436CF7;

    }
    header nav .nav .menuH  .special a{
        color: #fff;
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
        line-height: 0;
        height:auto;
        padding:0;
        margin:0;
    }
    header nav .nav .menuH  .special div:hover a{
        color: #fff;
    }


    header nav .WORK{
        position:relative;
    }

    header .logo{
        width:12rem;
        display: inline-block;
        vertical-align: top;
        height:100%;
        position: relative;
    }

    header .logo a img{
        height:3.2rem;
        transition: all 0.7s ease-in-out;
        position: absolute;
        left:0;
        top:50%;
        transform: translateY(-50%);
    }

    header nav .menuBottom{
        text-align: left;
        position:relative;
        box-sizing: border-box;
        transition: all 0.7s ease-in-out;
        padding-top: 0.8rem;
    }

    header nav.fixed-nav {
         top: 0;
         right:0;
         left:0;
         position: fixed!important;
     }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    header nav{
        width:100%;
        min-height:4rem;
        z-index: 900;
        box-sizing: border-box;
        position:fixed;
        font-family: 'Roboto', sans-serif;
        transition: all 0.7s ease-in-out;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.51);
        background:#fff;
        padding:0 1rem;
    }

    header nav .logoM{
        display:none;
    }

    header nav button{
        display: none;
    }
    header nav .nav {
        position: relative;
        height:4rem;
        transition: all 0.7s ease-in-out;
        vertical-align: top;
        box-sizing: border-box;
        width:100%;
        margin: 0 auto;
        z-index: 11;
    }

    header nav .nav .menuH {
        width:calc(100% - 9rem);
        height:100%;
        display: inline-block;
        vertical-align: top;
        text-align: right;
    }

    header nav .nav .menuH div{
        display: inline-block;
        position: relative;
        height:100%;
        vertical-align: top;
    }
    header nav .nav .menuH .chev{
        width:1.3rem;
        padding-top: 1rem;
        text-align: center;
    }
    header nav .nav .menuH .chev div{
        height:45% !important;
        width:1px;
        background: #686868;
        content: ' ' ;
        transform: skew(-30deg);
    }

    header nav .nav .menuH  div .a{
        text-decoration: none;
        text-align: center;
        display: inline-block;
        color: #686868;
        font-size:1em;
        font-weight: 500;
        padding:0.2rem 1rem;
        box-sizing: border-box;
        height:100%;
        line-height: 3.5rem;
        transition: all 0.2s ease-in-out;
    }
    header nav .nav .menuH  div i{
        position: absolute;
        top:2.7rem;
        left:50%;
        transform: translateX(-50%);
        color:#436CF7;
        font-size: 0.8rem;
        transition: all 0.2s ease-in-out;
        opacity: 0;
    }

    header nav .nav .menuH  div:hover i, header nav .nav .menuH  div .activeA i{
        opacity: 1;
    }
    header nav .nav .menuH  div:hover .a, header nav .nav .menuH  div .activeA{
        color: #436CF7;
    }
    header nav .nav .menuH  div .border{
        background:#436CF7;
        width:100%;
        height:0.4rem;
        border-radius: 2px 2px 0 0;
        content: ' ' ;
        position: absolute;
        bottom:0;
        left:0;
        transition: all 0.2s ease-in-out;
        opacity: 0;
    }
    header nav .nav .menuH  div:hover .border, header nav .nav .menuH  div .activeA .border{
        opacity: 1;
    }

    header nav .nav .menuH  .special{
        height:3.5rem;
        width:9rem;
        overflow: hidden;
        display: inline-block;

    }
    header nav .nav .menuH  .special div{
        background: #1048A0;
        height:3.5rem;
        width:100%;
        margin: 0.5rem 0 0 13px;
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
        overflow: hidden;
        position: relative;
        transition: all 0.2s ease-in-out;

    }
    header nav .nav .menuH  .special div:hover{
        background: #436CF7;

    }
    header nav .nav .menuH  .special a{
        color: #fff;
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        right: 1rem;
        bottom: 0;
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -o-transform: skew(30deg);
        line-height: 0;
        height:auto;
        padding:0;
        margin:0;
    }
    header nav .nav .menuH  .special div:hover a{
        color: #fff;
    }


    header nav .WORK{
        position:relative;
    }

    header .logo{
        width:9rem;
        display: inline-block;
        vertical-align: top;
        height:100%;
        position: relative;
    }

    header .logo a img{
        height:2.8rem;
        transition: all 0.7s ease-in-out;
        position: absolute;
        left:0;
        top:50%;
        transform: translateY(-50%);
    }

    header nav .menuBottom{
        text-align: left;
        position:relative;
        box-sizing: border-box;
        transition: all 0.7s ease-in-out;
        padding-top: 0.8rem;
    }

    header nav.fixed-nav {
        top: 0;
        right:0;
        left:0;
        position: fixed!important;
    }
}

@media screen and (max-width: 900px){

    .svg{
        fill:#fff;
    }

    header nav .menuMiddle{
        display: none !important;
    }

    header nav .menuTop{
        display: none !important;
    }

    header nav .logoM{
        height:4rem;
        position: absolute;
        top:0.5rem;
        left:1rem;
    }
    header nav .logoM img{
        height:3rem;
    }

    header nav{
        width:100%;
        background-color: #fff;
        z-index: 900;
        position:fixed;
        top:0;
        left:0;
        right:0;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.51);
    }

    header nav button{
        display: block;
        color:transparent;
        border: none;
        margin: 0 auto;
        outline: none;

    }

    header nav .nav {
        height: auto;
        display: block;
        padding: 58px 0 0.5em 0;
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
        max-height: 0;
        background-color: #fff;
    }

    header nav .nav div{
        display: block;
        text-align: center;
    }

    header nav .nav div a{
        text-decoration: none;
        font-size: 1.2em;
        line-height: 1.5em;
        text-align: center;
        padding: 0.4em 0;
        display: block;
        color:#000;
    }

    header nav .nav div i{
        display: none;
    }

    header nav .nav .ddl .ddl-div{
        display: block;
        font-size: 0.872em;
        padding: 0em 0 0.5em;
        max-height: 0;
        overflow: hidden;
        height: auto;
        transition: max-height 0.5s ease-in-out;
        background-color: #fff;
    }

    header nav .nav .ddl .ddl-div a{
        display: block;
        text-align: center;
        padding: 0.4em 0;
    }

    header nav.fixed-nav {
        top: 0;
        position: fixed!important;
    }

    header nav .WORK{
        position:relative;
    }

    header .book{
        text-decoration: none;
        font-size: 1.2em;
        line-height: 1.5em;
        text-align: center;
        padding: 0.4em 0;
        display: block;
        color:#000;
    }

    .menu-toggle {
        width: 50px;
        height: 50px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        top: 10px;
        right: 1rem;
        position: absolute;
        z-index: 151;
        background-color: #fff;
    }

    .menu-toggle span{
        display: block;
        position: absolute;
        height: 5px;
        width: 51%;
        background-color: #1048A0;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    .menu-toggle span:nth-child(odd) {
        left: 0px;
        border-radius: 9px 0 0 9px;
    }

    .menu-toggle span:nth-child(even) {
        left: 49%;
        border-radius: 0 9px 9px 0;
    }

    .menu-toggle span:nth-child(1), .menu-toggle span:nth-child(2) {
        top: 10px;
    }

    .menu-toggle span:nth-child(3), .menu-toggle span:nth-child(4) {
        top: 22px;
    }

    .menu-toggle span:nth-child(5), .menu-toggle span:nth-child(6) {
        top: 34px;
    }

    .menu-toggle.open span:nth-child(1) {
        left: 4px;
        top: 14px;
    }

    .menu-toggle.open span:nth-child(2) {
        left: 42%;
        top: 14px;
    }

    .menu-toggle.open span:nth-child(3) {
        left: -50%;
        opacity: 0;
    }

    .menu-toggle.open span:nth-child(4) {
        left: 100%;
        opacity: 0;
    }

    .menu-toggle.open span:nth-child(5) {
        left: 4px;
        top: 29px;
    }

    .menu-toggle.open span:nth-child(6) {
        left: 42%;
        top: 29px;
    }

    .menu-toggle.open span:nth-child(1), .menu-toggle.open span:nth-child(6) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .menu-toggle.open span:nth-child(2), .menu-toggle.open span:nth-child(5) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    header .logo{
        display:none;
    }
    header .logo img{
        display:none;
    }

    header .booking{
        display:none;
    }
}