@media screen and (min-width: 1300px) {
    .mapContainer {
        margin: auto;
        width: 100%;
        height: 45rem;
        position: relative;
        pointer-events: none;
    }

    .mapContainer .map{
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 100;
        pointer-events: none;
    }

    .storeContainer {
        position: absolute;
        top: 0;
        left: 0;
        z-Index: 100;
        background-color: #AADAFF;
        width: 60%;
        height: 45rem;
        padding: 4rem 3rem;
        box-sizing: border-box;
        text-align: right;
        pointer-events: none;
    }

    .mapContainer .right{
        position: absolute;
        top: 4rem;
        left: 50%;
        transform: translateX(-50%);
        width:1250px;
        z-Index: 140;
        box-sizing: border-box;
        pointer-events: all;
    }
    .mapContainer .right .head div{
        background:#1048A0;
        height:1px;
        width:3rem;
        display: inline-block;
        vertical-align: middle;
        margin-right:1rem;
    }
    .mapContainer .right .head h1{
        font-size: 1.2rem;
        letter-spacing: 2px;
        color:#1048A0;
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
    }

    .mapContainer .right h2{
        font-weight: 800;
        font-size: 1.8rem;
        margin-top: 1rem;
        margin-bottom: 0.6rem;
    }
    .mapContainer .right .form{
        width:25rem;
        margin-top: 1rem;
    }
    .mapContainer .right .form textarea{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
        resize: none;
        height:4rem;
        font-family: 'Roboto', sans-serif;
    }
    .mapContainer .right .form input[type=Checkbox]{
        width:2rem;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2rem;
    }
    .mapContainer .right .form p{
        width: calc(100% - 2rem);
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2rem;
    }

    .mapContainer .right .button{
        border-radius:5px ;
        background:#1048A0;
        border: 0;
        height:2.5rem;
        width:100%;
        display: block;
        vertical-align: top;
        color:#fff;
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        transition: all 0.2s ease-in-out;
    }
    .mapContainer .right .button:hover{
        background:#436CF7;
    }
    .mapContainer .right .col2{
        display: flex;
        justify-content: space-between;
    }

    .mapContainer .right input, .mapContainer .right select{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
    }

    .mapContainer .right input::placeholder, .mapContainer .right select::placeholder, .mapContainer .right textarea::placeholder{
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }

    .mapContainer .right .col2 input, .mapContainer .right .col2 select{
        width:calc(100%/2 - 0.5rem)
    }

    .mapContainer .right .add{
        min-height:2rem;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        margin-bottom: 1rem;
        position: relative;
        padding:0.5rem 2rem 0.5rem 0 ;
        box-sizing: border-box;
    }
    .mapContainer .right .add span{
        line-height: 1.5rem;
        margin-left: 0.75rem;
        vertical-align: top;
        display: inline-block;
    }
    .mapContainer .right .add .extra{
        width: calc(100% - 8rem);
        display: inline-block;
        vertical-align: top;
    }
    .mapContainer .right .add .extra span{
        display: block;
        line-height: 1.5rem;
    }
    .mapContainer .right .add button{
        position: absolute;
        top:0.75rem;
        right:0.75rem;
        border:0;
        background: transparent;
        padding:0;
    }
    .mapContainer .right .add button i{
        font-size: 1.2rem;
        color:#1048A0;
    }
    .mapContainer .right .add button:hover i{
        font-size: 1.2rem;
        color:#436CF7;
    }


    .pop{
        width:500px;
        padding:1rem;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
    }
    .pop h2{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .pop select{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
    }

    .pop select::placeholder{
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }
    .pop button{
        border-radius:5px ;
        background:#1048A0;
        border: 0;
        height:2.5rem;
        width:100%;
        display: block;
        vertical-align: top;
        color:#fff;
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        transition: all 0.2s ease-in-out;
        margin-top: 1rem;
    }
    .pop button:hover{
        background:#436CF7;
    }
    .pop .chk{
        margin: 0.2rem 0;
    }

    .pop .chk input{
        width:1.1rem;
        height:1.1rem;
        margin: 0;
        vertical-align: middle;
        display: inline-block;
    }
    .pop .chk p{
        width:calc(100% - 8.4rem);
        vertical-align: middle;
        display: inline-block;
        padding: 0 0.5rem;
        box-sizing: border-box;
    }
    .pop .chk span{
        text-align: right;
        width:7rem;
        vertical-align: middle;
        display: inline-block;
    }
}
@media screen and (max-width: 1300px) and (min-width: 900px) {
    .mapContainer {
        margin: auto;
        width: 100%;
        height: 45rem;
        position: relative;
        pointer-events: none;
    }

    .mapContainer .map{
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 100;
        pointer-events: none;
    }

    .storeContainer {
        position: absolute;
        top: 0;
        left: 0;
        z-Index: 100;
        background-color: #AADAFF;
        width: 60%;
        height: 45rem;
        padding: 4rem 3rem;
        box-sizing: border-box;
        text-align: right;
        pointer-events: none;
    }

    .mapContainer .right{
        position: absolute;
        top: 4rem;
        left: 50%;
        transform: translateX(-50%);
        width:100%;
        z-Index: 140;
        box-sizing: border-box;
        pointer-events: all;
        padding:0 1rem;
    }
    .mapContainer .right .head div{
        background:#1048A0;
        height:1px;
        width:3rem;
        display: inline-block;
        vertical-align: middle;
        margin-right:1rem;
    }
    .mapContainer .right .head h1{
        font-size: 1.2rem;
        letter-spacing: 2px;
        color:#1048A0;
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
    }

    .mapContainer .right h2{
        font-weight: 800;
        font-size: 1.8rem;
        margin-top: 1rem;
        margin-bottom: 0.6rem;
    }
    .mapContainer .right .form{
        width:25rem;
        margin-top: 1rem;
    }
    .mapContainer .right .form textarea{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
        resize: none;
        height:4rem;
        font-family: 'Roboto', sans-serif;
    }
    .mapContainer .right .form input[type=Checkbox]{
        width:2rem;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2rem;
    }
    .mapContainer .right .form p{
        width: calc(100% - 2rem);
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2rem;
    }

    .mapContainer .right .button{
        border-radius:5px ;
        background:#1048A0;
        border: 0;
        height:2.5rem;
        width:100%;
        display: block;
        vertical-align: top;
        color:#fff;
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        transition: all 0.2s ease-in-out;
    }
    .mapContainer .right .button:hover{
        background:#436CF7;
    }
    .mapContainer .right .col2{
        display: flex;
        justify-content: space-between;
    }

    .mapContainer .right input, .mapContainer .right select{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
    }

    .mapContainer .right input::placeholder, .mapContainer .right select::placeholder, .mapContainer .right textarea::placeholder{
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }

    .mapContainer .right .col2 input, .mapContainer .right .col2 select{
        width:calc(100%/2 - 0.5rem)
    }

    .mapContainer .right .add{
        min-height:2rem;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        margin-bottom: 1rem;
        position: relative;
        padding:0.5rem 2rem 0.5rem 0 ;
        box-sizing: border-box;
    }
    .mapContainer .right .add span{
        line-height: 1.5rem;
        margin-left: 0.75rem;
        vertical-align: top;
        display: inline-block;
    }
    .mapContainer .right .add .extra{
        width: calc(100% - 8rem);
        display: inline-block;
        vertical-align: top;
    }
    .mapContainer .right .add .extra span{
        display: block;
        line-height: 1.5rem;
    }
    .mapContainer .right .add button{
        position: absolute;
        top:0.75rem;
        right:0.75rem;
        border:0;
        background: transparent;
        padding:0;
    }
    .mapContainer .right .add button i{
        font-size: 1.2rem;
        color:#1048A0;
    }
    .mapContainer .right .add button:hover i{
        font-size: 1.2rem;
        color:#436CF7;
    }

    .pop{
        width:500px;
        padding:1rem;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
    }
    .pop h2{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .pop select{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
    }

    .pop select::placeholder{
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }
    .pop button{
        border-radius:5px ;
        background:#1048A0;
        border: 0;
        height:2.5rem;
        width:100%;
        display: block;
        vertical-align: top;
        color:#fff;
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        transition: all 0.2s ease-in-out;
        margin-top: 1rem;
    }
    .pop button:hover{
        background:#436CF7;
    }
    .pop .chk{
        margin: 0.2rem 0;
    }

    .pop .chk input{
        width:1.1rem;
        height:1.1rem;
        margin: 0;
        vertical-align: middle;
        display: inline-block;
    }
    .pop .chk p{
        width:calc(100% - 8.4rem);
        vertical-align: middle;
        display: inline-block;
        padding: 0 0.5rem;
        box-sizing: border-box;
    }
    .pop .chk span{
        text-align: right;
        width:7rem;
        vertical-align: middle;
        display: inline-block;
    }
}

@media screen and (max-width: 900px){
    .mapContainer {
        margin: 0 0 2rem 0;
        width: 100%;
        position: relative;
    }

    .mapContainer .map{
        width: 100%;
        height: 35rem;
        position: relative;
    }
    .mapContainer .pMap{
        width: 100%;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .mapContainer .right{
        width:100%;
        box-sizing: border-box;
        padding:0 1rem 4rem 1rem;
    }

    .mapContainer .right .head div{
        background:#1048A0;
        height:1px;
        width:3rem;
        display: inline-block;
        vertical-align: middle;
        margin-right:1rem;
    }
    .mapContainer .right .head h1{
        font-size: 1.2rem;
        letter-spacing: 2px;
        color:#1048A0;
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
    }

    .mapContainer .right h2{
        font-weight: 800;
        font-size: 1.8rem;
        margin-top: 1rem;
        margin-bottom: 0.6rem;
    }
    .mapContainer .right .form{
        width:100%;
        margin-top: 1rem;
    }

    .mapContainer .right .form textarea{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
        resize: none;
        height:4rem;
        font-family: 'Roboto', sans-serif;
    }
    .mapContainer .right .form input[type=Checkbox]{
        width:2rem;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2rem;
    }
    .mapContainer .right .form p{
        width: calc(100% - 2rem);
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2rem;
    }

    .mapContainer .right .button{
        border-radius:5px ;
        background:#1048A0;
        border: 0;
        height:2.5rem;
        width:100%;
        display: block;
        vertical-align: top;
        color:#fff;
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        transition: all 0.2s ease-in-out;
    }
    .mapContainer .right .button:hover{
        background:#436CF7;
    }
    .mapContainer .right .col2{
        display: flex;
        justify-content: space-between;
    }

    .mapContainer .right input, .mapContainer .right select{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
    }

    .mapContainer .right input::placeholder, .mapContainer .right select::placeholder, .mapContainer .right textarea::placeholder{
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }

    .mapContainer .right .col2 input, .mapContainer .right .col2 select{
        width:calc(100%/2 - 0.5rem)
    }

    .mapContainer .right .add{
        min-height:2rem;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        margin-bottom: 1rem;
        position: relative;
        padding:0.5rem 2rem 0.5rem 0 ;
        box-sizing: border-box;
    }
    .mapContainer .right .add span{
        line-height: 1.5rem;
        margin-left: 0.75rem;
        vertical-align: top;
        display: inline-block;
    }
    .mapContainer .right .add .extra{
        width: calc(100% - 8rem);
        display: inline-block;
        vertical-align: top;
    }
    .mapContainer .right .add .extra span{
        display: block;
        line-height: 1.5rem;
    }
    .mapContainer .right .add button{
        position: absolute;
        top:0.75rem;
        right:0.75rem;
        border:0;
        background: transparent;
        padding:0;
    }
    .mapContainer .right .add button i{
        font-size: 1.2rem;
        color:#1048A0;
    }
    .mapContainer .right .add button:hover i{
        font-size: 1.2rem;
        color:#436CF7;
    }

    .pop{
        width:100%;
        padding:1rem;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
    }
    .pop h2{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .pop select{
        width:calc(100%);
        padding:0.5rem 0.7rem;
        box-sizing: border-box;
        border-radius: 5px;
        border:1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        margin-bottom: 1rem;
        font-size: 1rem;
        outline: none;
    }

    .pop select::placeholder{
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }
    .pop button{
        border-radius:5px ;
        background:#1048A0;
        border: 0;
        height:2.5rem;
        width:100%;
        display: block;
        vertical-align: top;
        color:#fff;
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        transition: all 0.2s ease-in-out;
        margin-top: 1rem;
    }
    .pop button:hover{
        background:#436CF7;
    }
    .pop .chk{
        margin: 0.2rem 0;
    }

    .pop .chk input{
        width:1.1rem;
        height:1.1rem;
        margin: 0;
        vertical-align: middle;
        display: inline-block;
    }
    .pop .chk p{
        width:calc(100% - 6.4rem);
        vertical-align: middle;
        display: inline-block;
        padding: 0 0.5rem;
        box-sizing: border-box;
    }
    .pop .chk span{
        text-align: right;
        width:5rem;
        vertical-align: middle;
        display: inline-block;
    }
}