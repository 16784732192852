#How {
    width:100%;
    background: rgba(16, 72, 160, 0.1);
    padding:4rem 0;
}
#How .h1{
    font-size: 1.2rem;
    letter-spacing: 2px;
    color:#1048A0;
    font-weight: 400;
    text-align: center;
    margin-bottom: 1.5rem;
}
#How .h2{
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 0.5rem;
}
#How .p{
    font-size: 1rem;
    text-align: center;
    width:800px;
    margin: 0 auto 3rem auto;
}


#How .left .head div{
    background:#1048A0;
    height:1px;
    width:3rem;
    display: inline-block;
    vertical-align: middle;
    margin-right:1rem;
}
#How .left .head h1{
    font-size: 1.2rem;
    letter-spacing: 2px;
    color:#1048A0;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
}


#How .left .item{
    width:100%;
    margin-bottom: 0.5rem;
}
#How .left .item i{
    color:#1048A0;
    font-size: 1.2rem;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    width:1.2rem;
}
#How .left .item span{
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
}

#How .left .row{
    width:calc(100% - 4rem);
    position: absolute;
    bottom:2rem;
    left:2rem;
}
#How .left .row button{
    background:transparent;
    border:1px solid #1048A0;
    color:#1048A0;
    border-radius: 3px;
    height:2rem;
    width:2rem;
    font-size:1.1rem;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    margin-right: 1.2rem;
    transition: all 0.2s ease-in-out;
}

#How .left .row button:hover{
    background:#1048A0;
    color:#fff;
}



@media screen and (min-width: 1300px) {

    #How .left{
        width:100%;
        display:flex;
        justify-content: space-evenly;
        vertical-align: top;
        background:#fff;
        padding:2rem;
        box-sizing: border-box;
        position: relative;
    }
    #How .left .col{
        text-align: center;
        width: calc(100%/3 - 2rem );
    }
    #How .left h2{
        font-size: 1.8rem;
        margin:1rem 0;
    }

    #How .right{
        width:60%;
        display:inline-block;
        vertical-align: top;
        background-color: #fff;
    }
    #How .right .slideItem{
        height:35rem;
        position: relative;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    #How .left{
        width:100%;
        display:flex;
        justify-content: space-evenly;
        vertical-align: top;
        background:#fff;
        padding:2rem 0;
        box-sizing: border-box;
        position: relative;
    }
    #How .left .col{
        text-align: center;
        width: calc(100%/3 - 2rem );
    }
    #How .left h2{
        font-size: 1.6rem;
        margin:1rem 0;
    }

    #How .right{
        width:60%;
        display:inline-block;
        vertical-align: top;
        background-color: #fff;
    }
    #How .right .slideItem{
        height:30rem;
        position: relative;
    }
}

@media screen and (max-width: 900px){
    #How .left{
        width:100%;
        display:inline-block;
        vertical-align: top;
        background:#fff;
        min-height:30rem;
        padding:2rem;
        box-sizing: border-box;
        position: relative;
    }
    #How .left .col{
        text-align: center;
        width: calc(100%);
        margin-bottom: 4rem;
    }
    #How .left h2{
        font-size: 1.6rem;
        margin:1rem 0;
    }

    #How .right{
        width:100%;
        display:inline-block;
        vertical-align: top;
        background-color: #fff;
    }
    #How .right .slideItem{
        height:30rem;
        position: relative;
    }
}