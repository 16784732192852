#Home{
    position: relative;
}

#Home .overlay{
    background: rgba(1, 6, 13, 0.5);
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
}

#Home .social{
    width:2.5rem;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right:1rem;
}

#Home .social a{
    width:100%;
    height:2.5rem;
    display: block;
    margin-bottom: 1rem;
    background: #1048A0;
    color:#fff;
    text-align: center;
    transition: all 0.2s ease-in-out;
}
#Home .social a:hover{
    background: #436CF7;
}
#Home .social a i{
    font-size: 1.5rem;
    line-height: 2.5rem;
}
#Home .box img{
    position: absolute;
    top:-2rem;
    right:-5rem;
    height:30rem;
}

#Home .box button:hover{
    background:#436CF7;
}

#Home .bottom{
    width:100%;
    position: absolute;
    bottom:0;
    left:0;
    background: #23489E;
    min-height:5rem;
    padding:0.5rem 0;
    box-sizing: border-box;
}
#Home .bottom .split{
    width:20%;
    height:4rem;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}
#Home .bottom .split div{
    width:1px;
    height:100%;
    background: #fff;
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%) skew(-30deg);
}



@media screen and (min-width: 1300px) {
    .container{
        width:1250px;
        margin:0 auto;
    }
    #Home{
        box-sizing: border-box;
        height:45rem;
        width:100%;
        overflow: hidden;
        background:url('/Graphic/header.jpg') no-repeat center -16rem;
        background-size: cover;
    }
    #Home .box{
        width:1250px;
        position: absolute;
        top:15rem;
        left:50%;
        transform: translateX(-50%);
        padding: 0 0 0 2rem;
        box-sizing: border-box;
    }
    #Home .box div{
        width:0.5rem;
        position: absolute;
        top:0;
        left:0;
        height:105%;
        background: #23489E;
        border-radius: 2px;
    }

    #Home .box h1{
        color:#fff;
        font-size:40px;
        box-sizing: border-box;
        font-weight: 700;
        padding: 1rem 40% 0 0;
    }

    #Home .box p{
        color: #fff;
        font-size:20px;
        box-sizing: border-box;
        font-weight: 400;
        padding: 2rem 40% 3.3rem 0;
    }
    #Home .box button{
        border:0;
        background:#23489E;
        font-size: 1.1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        padding: 0.8rem 2.5rem;
        border-radius: 3px;
        color:#fff;
        cursor: pointer;
        transition: all 0.2s linear;
        letter-spacing: 1px;
    }

    #Home .bottom .div{
        width:40%;
        display: inline-block;
    }
    #Home .bottom .split{
        width:20%;
        height:4rem;
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }
    #Home .bottom .div .img{
        display: inline-block;
        vertical-align: middle;
        height:3.5rem;
        width:5.5rem;
        text-align: center;
    }

    #Home .bottom .div .img img{
        height:100%;
    }
    #Home .bottom .div .text {
        display: inline-block;
        vertical-align: middle;
        color:#fff;
        padding-left: 2rem;
        box-sizing: border-box;
        width:calc(100% - 5.5rem);
    }
    #Home .bottom .div .text span{
        font-size: 1.3rem;
        font-weight: 500;
    }
    #Home .bottom .div .text p{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    .container{
        width:100%;
        padding:0 1rem;
        box-sizing: border-box;
    }
    #Home{
        box-sizing: border-box;
        height:35rem;
        width:100%;
        overflow: hidden;
        background:url('/Graphic/header.jpg') no-repeat center center;
        background-size: cover;
    }
    #Home .box{
        width:100%;
        position: absolute;
        top:8rem;
        padding: 0 0 0 3rem;
        box-sizing: border-box;
    }
    #Home .box div{
        width:0.5rem;
        position: absolute;
        top:0;
        left:1rem;
        height:105%;
        background: #23489E;
        border-radius: 2px;
    }
    #Home .box h1{
        color:#fff;
        font-size:35px;
        box-sizing: border-box;
        font-weight: 700;
        padding: 1rem 40% 0 0;
    }

    #Home .box p{
        color: #fff;
        font-size:18px;
        box-sizing: border-box;
        font-weight: 400;
        padding: 2rem 40% 3.3rem 0;
    }
    #Home .box button{
        border:0;
        background:#23489E;
        font-size: 1.1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        padding: 0.8rem 2.5rem;
        border-radius: 3px;
        color:#fff;
        cursor: pointer;
        transition: all 0.2s linear;
        letter-spacing: 1px;
    }

    #Home .bottom .div{
        width:45%;
        display: inline-block;
    }
    #Home .bottom .split{
        width:10%;
        height:4rem;
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }
    #Home .bottom .div .img{
        display: inline-block;
        vertical-align: middle;
        height:3.5rem;
        width:5.5rem;
        text-align: center;
    }

    #Home .bottom .div .img img{
        height:100%;
    }
    #Home .bottom .div .text {
        display: inline-block;
        vertical-align: middle;
        color:#fff;
        padding-left: 2rem;
        box-sizing: border-box;
        width:calc(100% - 5.5rem);
    }
    #Home .bottom .div .text span{
        font-size: 1.3rem;
        font-weight: 500;
    }
    #Home .bottom .div .text p{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 900px){
    .container{
        width:100%;
        padding:0 1rem;
        box-sizing: border-box;
    }
    #Home{
        box-sizing: border-box;
        height:40rem;
        width:100%;
        overflow: hidden;
        background:url('/Graphic/header.jpg') no-repeat -100px center;
        background-size: cover;
    }
    #Home .box{
        width:100%;
        position: absolute;
        top:8rem;
        padding: 0 0 0 3rem;
        box-sizing: border-box;
    }
    #Home .box div{
        width:0.5rem;
        position: absolute;
        top:0;
        left:1rem;
        height:105%;
        background: #23489E;
        border-radius: 2px;
    }
    #Home .box h1{
        color:#fff;
        font-size:25px;
        box-sizing: border-box;
        font-weight: 700;
        padding: 1rem 40% 0 0;
    }

    #Home .box p{
        color: #fff;
        font-size:14px;
        box-sizing: border-box;
        font-weight: 400;
        padding: 2rem 20% 1.7rem 0;
    }
    #Home .box button{
        border:0;
        background:#23489E;
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        padding: 0.8rem 2rem;
        border-radius: 3px;
        color:#fff;
        cursor: pointer;
        transition: all 0.2s linear;
        letter-spacing: 1px;
    }

    #Home .bottom .div{
        width:100%;
        display: inline-block;
        margin: 0.5rem 0;
    }
    #Home .bottom .div .img{
        display: inline-block;
        vertical-align: middle;
        height:2.5rem;
        width:3.5rem;
        text-align: center;
    }

    #Home .bottom .div .img img{
        height:100%;
    }
    #Home .bottom .div .text {
        display: inline-block;
        vertical-align: middle;
        color:#fff;
        padding-left: 2rem;
        box-sizing: border-box;
        width:calc(100% - 3.5rem);
    }
    #Home .bottom .div .text span{
        font-size: 1.1rem;
        font-weight: 500;
    }
    #Home .bottom .div .text p{
        font-size: 0.8rem;
    }
    #Home .bottom .split{
        display: none;
    }
}