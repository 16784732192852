#Meeting .overlay{
    background: rgba(1, 6, 13, 0.5);
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
}

#Meeting .social{
    width:2.5rem;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right:1rem;
}

#Meeting .social a{
    width:100%;
    height:2.5rem;
    display: block;
    margin-bottom: 1rem;
    background: #1048A0;
    color:#fff;
    text-align: center;
    transition: all 0.2s ease-in-out;
}
#Meeting .social a:hover{
    background: #436CF7;
}
#Meeting .social a i{
    font-size: 1.5rem;
    line-height: 2.5rem;
}

#Meeting .bottom{
    width:100%;
    position: absolute;
    bottom:0;
    left:0;
    background: #23489E;
    min-height:5rem;
    padding:0.5rem 0;
    box-sizing: border-box;
}


.mBottom .head div{
    background:#1048A0;
    height:1px;
    width:3rem;
    display: inline-block;
    vertical-align: middle;
    margin-right:1rem;
}
.mBottom .head h1{
    font-size: 1.2rem;
    letter-spacing: 2px;
    color:#1048A0;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
}

.mBottom .social{
}

.mBottom .social a{
    width:2.5rem;
    height:2.5rem;
    margin: 3rem 1rem 3rem 1rem;
    background: #1048A0;
    color:#fff;
    text-align: center;
    transition: all 0.2s ease-in-out;
    display: inline-block;
}
.mBottom .social a:hover{
    background: #436CF7;
}
.mBottom .social a i{
    font-size: 1.5rem;
    line-height: 2.5rem;
}


@media screen and (min-width: 1300px) {
    #Meeting{
        box-sizing: border-box;
        height:45rem;
        width:100%;
        overflow: hidden;
        background:url('/Graphic/header.jpg') no-repeat center center;
        background-size: 100% auto;
        position: relative;
    }
    #Meeting .box{
        width:1250px;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        padding: 0 0 0 2rem;
        box-sizing: border-box;
    }
    #Meeting .box div{
        width:0.5rem;
        position: absolute;
        top:0;
        left:0;
        height:105%;
        background: #23489E;
        border-radius: 2px;
    }

    #Meeting .box h1{
        color:#fff;
        font-size:40px;
        box-sizing: border-box;
        font-weight: 700;
        padding: 1rem 40% 0 0;
        line-height: 12rem;
    }

    .mBottom h2{
        font-size: 1.8rem;
        letter-spacing: 1px;
        color:#1048A0;
        font-weight: 600;
        margin:1.5rem 0 3rem 0;
    }

    #Meeting .box p{
        color: #fff;
        font-size:20px;
        box-sizing: border-box;
        font-weight: 400;
        padding: 2rem 40% 3.3rem 0;
    }
    #Meeting .box button{
        border:0;
        background:#23489E;
        font-size: 1.1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        padding: 0.8rem 2.5rem;
        border-radius: 3px;
        color:#fff;
        cursor: pointer;
        transition: all 0.2s linear;
        letter-spacing: 1px;
    }

    #Meeting .bottom .div{
        width:40%;
        display: inline-block;
    }
    #Meeting .bottom .split{
        width:20%;
        height:4rem;
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }
    #Meeting .bottom .div .img{
        display: inline-block;
        vertical-align: middle;
        height:3.5rem;
        width:5.5rem;
        text-align: center;
    }

    #Meeting .bottom .div .img img{
        height:100%;
    }
    #Meeting .bottom .div .text {
        display: inline-block;
        vertical-align: middle;
        color:#fff;
        padding-left: 2rem;
        box-sizing: border-box;
        width:calc(100% - 5.5rem);
    }
    #Meeting .bottom .div .text span{
        font-size: 1.3rem;
        font-weight: 500;
    }
    #Meeting .bottom .div .text p{
        font-size: 0.9rem;
    }

    .mBottom{
        text-align: center;
        padding:4rem 0;
    }
    .mBottom .info a{
        color:#1048A0;
        text-decoration: none;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        width:calc(100%/4 - 3rem);
        vertical-align: top;
    }
    .mBottom .partner{
        width:1250px;
    }
    .mBottom .partner img{
        height:6rem;
        margin: 0 1rem;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    #Meeting{
        box-sizing: border-box;
        height:45rem;
        width:100%;
        overflow: hidden;
        background:url('/Graphic/header.jpg') no-repeat center center;
        background-size: 100% auto;
        position: relative;
    }
    #Meeting .box{
        width:100%;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        padding: 0 0 0 3rem;
        box-sizing: border-box;
    }
    #Meeting .box div{
        width:0.5rem;
        position: absolute;
        top:0;
        left:1rem;
        height:105%;
        background: #23489E;
        border-radius: 2px;
    }
    #Meeting .box h1{
        color:#fff;
        font-size:35px;
        box-sizing: border-box;
        font-weight: 700;
        padding: 1rem 40% 0 0;
        line-height: 12rem;
    }

    .mBottom h2{
        font-size: 1.8rem;
        letter-spacing: 1px;
        color:#1048A0;
        font-weight: 600;
        margin:1.5rem 0 3rem 0;
    }

    #Meeting .box p{
        color: #fff;
        font-size:18px;
        box-sizing: border-box;
        font-weight: 400;
        padding: 2rem 40% 3.3rem 0;
    }
    #Meeting .box button{
        border:0;
        background:#23489E;
        font-size: 1.1rem;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        padding: 0.8rem 2.5rem;
        border-radius: 3px;
        color:#fff;
        cursor: pointer;
        transition: all 0.2s linear;
        letter-spacing: 1px;
    }

    #Meeting .bottom .div{
        width:45%;
        display: inline-block;
    }
    #Meeting .bottom .split{
        width:10%;
        height:4rem;
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }
    #Meeting .bottom .div .img{
        display: inline-block;
        vertical-align: middle;
        height:3.5rem;
        width:5.5rem;
        text-align: center;
    }

    #Meeting .bottom .div .img img{
        height:100%;
    }
    #Meeting .bottom .div .text {
        display: inline-block;
        vertical-align: middle;
        color:#fff;
        padding-left: 2rem;
        box-sizing: border-box;
        width:calc(100% - 5.5rem);
    }
    #Meeting .bottom .div .text span{
        font-size: 1.3rem;
        font-weight: 500;
    }
    #Meeting .bottom .div .text p{
        font-size: 0.9rem;
    }

    .mBottom{
        text-align: center;
        padding:4rem 1rem;
        box-sizing: border-box;
    }
    .mBottom .info a{
        color:#1048A0;
        text-decoration: none;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        width:calc(100%/4 - 2rem);
        vertical-align: top;
    }
    .mBottom .partner{
        width:100%;
    }
    .mBottom .partner img{
        margin: 0 1rem;
        height:6rem;
    }
}

@media screen and (max-width: 900px){
    #Meeting{
        box-sizing: border-box;
        height:45rem;
        width:100%;
        overflow: hidden;
        background:url('/Graphic/header.jpg') no-repeat -150px center;
        background-size: auto 100% ;
        position: relative;
    }
    #Meeting .box{
        width:100%;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        padding: 0 0 0 3rem;
        box-sizing: border-box;
    }
    #Meeting .box div{
        width:0.5rem;
        position: absolute;
        top:0;
        left:1rem;
        height:105%;
        background: #23489E;
        border-radius: 2px;
    }
    #Meeting .box h1{
        color:#fff;
        font-size:25px;
        box-sizing: border-box;
        font-weight: 700;
        padding: 1rem 40% 0 0;
        line-height: 10rem;
        hyphens: none;
    }

    .mBottom h2{
        font-size: 1.5rem;
        letter-spacing: 1px;
        color:#1048A0;
        font-weight: 600;
        margin:1.5rem 0 3rem 0;
    }

    #Meeting .bottom .div{
        width:100%;
        display: inline-block;
        margin: 0.5rem 0;
    }
    #Meeting .bottom .div .img{
        display: inline-block;
        vertical-align: middle;
        height:2.5rem;
        width:3.5rem;
        text-align: center;
    }

    #Meeting .bottom .div .img img{
        height:100%;
    }
    #Meeting .bottom .div .text {
        display: inline-block;
        vertical-align: middle;
        color:#fff;
        padding-left: 2rem;
        box-sizing: border-box;
        width:calc(100% - 3.5rem);
    }
    #Meeting .bottom .div .text span{
        font-size: 1.1rem;
        font-weight: 500;
    }
    #Meeting .bottom .div .text p{
        font-size: 0.8rem;
    }
    #Meeting .bottom .split{
        display: none;
    }

    .mBottom{
        text-align: center;
        padding:4rem 1rem;
        box-sizing: border-box;
    }
    .mBottom .info a{
        color:#1048A0;
        text-decoration: none;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        width:100%;
        margin-bottom: 2rem;
    }
    .mBottom .partner{
        width:100%;
    }
    .mBottom .partner img{
        height:5rem;
        margin: 0 auto;
    }
}