html, body{
    margin:0;
    padding: 0;
    overflow: auto!important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

.workbox-refresh
{
    z-index: 200;
    width: 100%;
    display: block;
    font-size: 1.3em;
    outline:none;
    border-top: 2px solid #3f3f3f;
    position: fixed!important;
    bottom:0;
    padding: 10px;
    box-sizing: border-box;
}

.workbox-refresh:hover
{
    text-decoration: underline;
    cursor: pointer;
}

#app{
    font-family: 'Roboto', sans-serif !important;
}

h1{
    font-size: 2em;
    margin:0;
}

h2{
    font-size: 1.7em;
    margin:0;
}

h3{
    font-size: 1.5em;
    margin:0;
}

p{
    font-size: 1em;
    margin:0;
}
button{
    cursor: pointer;
    outline: none;
}

.phone a {
    position: fixed;
    bottom: 2em;
    right: 2em;
    display: block;
    height: 2em;
    width: 2em;
    background-color: #fff;
    padding: 0.5em;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    z-index: 1000;
}

.phone a i {
    font-size: 2em;
    color:#E06125;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.phone a:hover {
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3);
}

.phone.hide {
    display: none;
}

@media screen and (min-width: 1300px) {
    #app{
        width:100%;
        min-height:calc(100vh - 350px);
    }

    .center{
        width:1170px;
        margin:0 auto ;
    }

    .fullwidth{
        width:100%;
    }

    header{
        width:100%;
    }

    section{
        width:100%;
        background: #fff;
    }

    footer{
        width:100%;
        background-color: #fff;
        min-height:300px;
        position: relative;
        overflow: hidden;
        font-family: 'Roboto', sans-serif;
    }
    #Footer .footC{
        width:1250px;
        margin: 0 auto;
        padding:  4em 1rem 2em 1rem;
        box-sizing: border-box;
        position: relative;
        text-align: center;
    }

    #Footer .top{
        width:100%;
        margin-bottom: 2rem;
    }
    #Footer .top img{
        width:14rem;
    }
    #Footer .link{
        display: flex;
        justify-content: space-between;
        width:600px;
        margin: 0 auto 2rem auto;
    }

    #Footer .link a{
        color:#1048A0;
        text-decoration: none;
        font-weight: 500;
    }

    #Footer .info a{
        color:#1048A0;
        text-decoration: none;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        width:calc(100%/4 - 3rem);
        vertical-align: top;
    }

    #Footer .copy{
        width:100%;
        text-align: center;
        height:3rem;
        z-index: 10;
        position: relative;
        border-top: 1px solid #1048A0;
    }

    #Footer .copy p{
        font-family: 'Montserrat', sans-serif;
        color:#1048A0;
        font-size: 0.9rem;
        line-height: 3rem;
        font-weight: 300;
    }

    #Footer .copy p a{
        font-family: 'Montserrat', sans-serif;
        color:#1048A0;
        line-height: 3rem;
        font-weight: 300;
        text-decoration: none;
    }

    #Footer .copy p a:hover{
        text-decoration: underline;
        color:#C31414;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    #app{
        width:100%;
        min-height:calc(100vh - 350px);
    }

    .center{
        margin:0 auto ;
    }

    .fullwidth{
        width:100%;
    }

    header{
        width:100%;
    }

    section{
        width:100%;
        background: #fff;
    }

    footer{
        background-color: #fff;
        min-height:300px;
        position: relative;
        overflow: hidden;
        font-family: 'Roboto', sans-serif;
    }
    #Footer .footC{
        width:100%;
        margin: 0 auto;
        padding:  4em 1rem 2em 1rem;
        box-sizing: border-box;
        position: relative;
        text-align: center;
    }

    #Footer .top{
        width:100%;
        margin-bottom: 2rem;
    }
    #Footer .top img{
        width:14rem;
        display: inline-block;
        vertical-align: middle;
    }
    #Footer .top p{
        width:calc(100% - 18rem);
        display: inline-block;
        vertical-align: middle;
    }
    #Footer .link{
        display: flex;
        justify-content: space-between;
        width:800px;
        margin: 0 auto 2rem auto;
    }

    #Footer .link a{
        color:#232323;
        text-decoration: none;
        font-weight: 800;
    }
    #Footer .info{
        position: relative;
        display: flex;
        justify-content: space-evenly;
        width:800px;
        margin:0 auto 2rem auto;
    }

    #Footer .info a{
        color:#1048A0;
        text-decoration: none;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        width:calc(100%/4 - 2rem);
        vertical-align: top;
    }

    #Footer .copy{
        width:100%;
        text-align: center;
        height:3rem;
        background: #232323;
        z-index: 10;
        position: relative;
    }

    #Footer .copy p{
        font-family: 'Montserrat', sans-serif;
        color:#fff;
        font-size: 1rem;
        line-height: 3rem;
        font-weight: 300;
    }

    #Footer .copy p a{
        font-family: 'Montserrat', sans-serif;
        color:#fff;
        font-size: 1rem;
        line-height: 3rem;
        font-weight: 300;
        text-decoration: none;
    }

    #Footer .copy p a:hover{
        text-decoration: underline;
        color:#C31414;
    }
}

@media screen and (max-width: 900px){
    #app{
        width:100%;
        min-height:100%;
        overflow: hidden;
    }

    .pad{
        padding : 0 1em;
        box-sizing: border-box;
    }

    .center{
        padding: 0 1em;
    }

    header{
        width:100%;
        background-color: black ;
    }

    section{
        width:100%;
        background: #fff;
    }

    footer{
        background-color: #fff;
        min-height:350px;
        position: relative;
        overflow: hidden;
        font-family: 'Roboto', sans-serif;
    }
    #Footer .footC{
        width:100%;
        margin: 0 auto;
        padding:  3em 1rem 2em 1rem;
        box-sizing: border-box;
        position: relative;
    }

    #Footer .top{
        width:100%;
        margin-bottom: 2rem;
        text-align: center;
    }
    #Footer .top img{
        width:14rem;
        display: inline-block;
    }
    #Footer .top p{
        width:100%;
        margin:1rem 0;
        display: inline-block;
        vertical-align: middle;
    }
    #Footer .link{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 2rem;
        width:100%;
        text-align: center;
    }

    #Footer .link a{
        font-size: 1.2rem;
        color:#232323;
        text-decoration: none;
        font-weight: 600;
        width: 100%;
        margin-top:0.8rem;
    }
    #Footer .info{
        padding-bottom: 2rem;
        position: relative;
    }
    #Footer .info a{
        color:#1048A0;
        text-decoration: none;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        width:calc(100%);
        margin-bottom: 1rem;
    }

    #Footer .copy{
        width:100%;
        text-align: center;
        height:3rem;
        background: #232323;
        z-index: 10;
        position: relative;
    }

    #Footer .copy p{
        font-family: 'Montserrat', sans-serif;
        color:#fff;
        font-size: 1rem;
        line-height: 3rem;
        font-weight: 300;
    }

    #Footer .copy p a{
        font-family: 'Montserrat', sans-serif;
        color:#fff;
        font-size: 1rem;
        line-height: 3rem;
        font-weight: 300;
        text-decoration: none;
    }

    #Footer .copy p a:hover{
        text-decoration: underline;
        color:#C31414;
    }
}