#Price {
    padding:4rem 0;
}

#Price .h1{
    font-size: 1.2rem;
    letter-spacing: 2px;
    color:#1048A0;
    font-weight: 400;
    text-align: center;
    margin-bottom: 1.5rem;
}
#Price .h2{
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 0.5rem;
}


#Price .d .cars .box.active{
    opacity: 1 !important;
}
#Price .d .cars .box span{
    font-size: 1.2rem;
    font-weight: 500;
    color:#1048A0;
}
#Price .d .cars .box .img{
    width:100%;
}
#Price .d .cars .box .img img{
    width:100%;
}




#Price .d .packs .box span:nth-child(1){
    display: block;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
}
#Price .d .packs .box span:nth-child(2){
    display: block;
    text-align: center;
    color:#1048A0;
    font-size: 2rem;
    font-weight: 800;
    margin:1rem 0;
}
#Price .d .packs .box .item{
    margin-bottom: 0.5rem;
}
#Price .d .packs .box .item i{
    color:#1048A0;
    font-size: 1.1rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
}
#Price .d .packs .box .item p{
    display: inline-block;
    vertical-align: middle;
    width:calc(100% - 2rem);
    font-size: 0.9rem;
    font-weight: 500;
}

#Price .d .packs .box .time {
    text-align: center;
    margin:2rem 0;
    position: absolute;
    bottom:5rem;
    left:50%;
    transform: translateX(-50%);
}
#Price .d .packs .box .time i{
    display: inline-block;
    vertical-align: middle;
    color:#1048A0;
    font-size: 1.5rem;
    margin-right: 1rem;
}
#Price .d .packs .box .time p{
    display: inline-block;
    vertical-align: middle;
    font-size: 1.1rem;
}

#Price .d .packs .box button{
    font-size: 1.1rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color:#fff;
    background:#1048A0;
    border:0;
    padding:0.8rem 2.5rem;
    border-radius:3px ;
    position: absolute;
    bottom:2.5rem;
    left:50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;
}
#Price .d .packs .box button:hover{
    background:#436CF7;
}

#Price .d .packs .special{
    background:url("/Graphic/service.jpg") no-repeat center center;
    background-size: cover;
    overflow: hidden;
}
#Price .d .packs .special .overlay{
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    box-sizing: border-box;
    background: rgba(2, 8, 19, 0.7);
}

#Price .d .packs .special button{
    color:#fff;
    background:#30BAFE;
}
#Price .d .packs .special button:hover{
    background:#436CF7;
}

#Price .d .packs .special .time i{
    color:#30BAFE;
}
#Price .d .packs .special .time p{
    color:#fff;
}
#Price .d .packs .special .item i{
    color:#30BAFE;
}
#Price .d .packs .special .item p{
    color:#fff;
}
#Price .d .packs .special span:nth-child(1){
    color:#fff;
}
#Price .d .packs .special span:nth-child(2){
    color:#30BAFE;
}

@media screen and (min-width: 1300px) {
    #Price .p{
        font-size: 1rem;
        text-align: center;
        width:800px;
        margin: 0 auto 3rem auto;
    }
    #Price .d .select{
        width:calc(50% - 0.5rem);
        border:1px solid rgba(16, 72, 160, 0.5);
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        height:1.5rem;
    }
    #Price .d .select:nth-child(1){
        margin-right: 1rem;
    }

    #Price .d .select select{
        width:90%;
        text-align-last: center;
        height:2rem;
        font-size: 1.2rem;
        font-weight: 600;
        background:#fff;
        border:0;
        position: absolute;
        left:50%;
        top:-1rem;
        transform: translateX(-50%);
        outline: none;
    }
    #Price .d .cars{
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
    }

    #Price .d .cars .box{
        width:calc(100%/5 - 3rem);
        text-align: center;
        opacity: 0.1 !important;
    }

    #Price .d .packs{
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
    }

    #Price .d .packs .box{
        width:calc(100%/1 - 2rem);
        border-radius:30px ;
        box-sizing: border-box;
        position: relative;
    }
    #Price .d .packs .tmp{
        position: relative;
        z-index: 10;
        padding:2rem 1.5rem 10rem 1.5rem;
        box-sizing: border-box;
        height:100%;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    #Price .p{
        font-size: 1rem;
        text-align: center;
        width:800px;
        margin: 0 auto 3rem auto;
    }
    #Price .d .select{
        width:calc(50% - 0.5rem);
        border:1px solid rgba(16, 72, 160, 0.5);
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        height:1.5rem;
    }
    #Price .d .select:nth-child(1){
        margin-right: 1rem;
    }

    #Price .d .select select{
        width:90%;
        text-align-last: center;
        height:2rem;
        font-size: 1.2rem;
        font-weight: 600;
        background:#fff;
        border:0;
        position: absolute;
        left:50%;
        top:-1rem;
        transform: translateX(-50%);
        outline: none;
    }
    #Price .d .cars{
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
    }

    #Price .d .cars .box{
        width:calc(100%/5 - 3rem);
        text-align: center;
        opacity: 0.1 !important;
    }

    #Price .d .packs{
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
    }

    #Price .d .packs .box{
        width:calc(100%/1 - 2rem);
        border-radius:30px ;
        box-sizing: border-box;
        position: relative;
    }
    #Price .d .packs .tmp{
        position: relative;
        z-index: 10;
        padding:2rem 1.5rem 10rem 1.5rem;
        box-sizing: border-box;
        height:100%;
    }
}

@media screen and (max-width: 900px){
    #Price .p{
        font-size: 1rem;
        text-align: center;
        width:100%;
        margin: 0 auto 3rem auto;
    }
    #Price .d .select{
        width:calc(100%);
        border:1px solid rgba(16, 72, 160, 0.5);
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        height:1.5rem;
        margin-bottom: 2rem;
    }
    #Price .d .select:nth-child(1){
        margin-right: 0;
    }

    #Price .d .select select{
        width:90%;
        text-align-last: center;
        height:2rem;
        font-size: 1.2rem;
        font-weight: 600;
        background:#fff;
        border:0;
        position: absolute;
        left:50%;
        top:-1rem;
        transform: translateX(-50%);
        outline: none;
    }
    #Price .d .cars{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 3rem;
    }

    #Price .d .cars .box{
        width:calc(100%/3 - 2rem);
        text-align: center;
        opacity: 0.1 !important;
    }

    #Price .d .packs{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 3rem;
    }

    #Price .d .packs .box{
        width:calc(100%/1);
        border-radius:30px ;
        box-sizing: border-box;
        position: relative;
    }

    #Price .d .packs .tmp{
        position: relative;
        z-index: 10;
        padding:2rem 1.5rem 10rem 1.5rem;
        box-sizing: border-box;
        height:100%;
    }
}