@media screen and (min-width: 1300px) {
    #Privat{
        background:#F5F5F5;
    }

    #Privat .container{
        width:1250px;
        margin: 0 auto;
        padding-top: 9rem;
        padding-bottom: 5rem;
    }

    #Privat .container .path{
        margin-bottom: 2rem;
        display: block;
    }

    #Privat .container .box{
        width:100%;
        padding:2rem;
        box-sizing: border-box;
        background: #fff;
        border-radius: 20px;
    }

    #Privat .container .box h1{
        color:#000;
        margin-bottom:2rem;
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    #Privat{
        padding: 0 1rem;
        box-sizing: border-box;
    }

    #Privat .container{
        max-width:1250px;
        margin: 0 auto;
        padding-top: 13rem;
        padding-bottom: 5rem;
    }

    #Privat .container .path{
        margin-bottom: 2rem;
        display: block;
    }

    #Privat .container .box{
        width:100%;
        padding:3rem 5rem;
        box-sizing: border-box;
        background: #fff;
    }

    #Privat .container .box h1{
        color:#000;
        margin-bottom:2rem;
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 900px){
    #Privat{
        padding: 0 1rem;
        box-sizing: border-box;
    }

    #Privat .container{
        max-width:1250px;
        margin: 0 auto;
        padding-top: 6rem;
        padding-bottom: 5rem;
    }

    #Privat .container .path{
        margin-bottom: 2rem;
        display: block;
    }

    #Privat .container .box{
        width:100%;
        padding:2rem 1rem;
        box-sizing: border-box;
        background: #fff;
    }

    #Privat .container .box h1{
        color:#000;
        margin-bottom:2rem;
        font-size: 2rem;
    }
}