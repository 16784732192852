#Review {
    width:100%;
    padding:4rem 0 6rem 0;
}
#Review .slideItem .box i{
    position: absolute;
    top:5rem;
    left:50%;
    transform: translateX(-50%);
    color: rgba(16, 72, 160, 0.10);
    font-size: 7rem;
}
#Review .slideItem .box .over{
}
#Review .slideItem .box .over .name{
    color:#1048A0;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: block;
}
#Review .slideItem .box .over p{
}
#Review .slideItem .box .over .img{
    overflow: hidden;
    border-radius:50%;
    height:5rem;
    width:5rem;
    position: absolute;
    bottom:3.5rem;
    left:50%;
    transform: translateX(-50%);
    margin:1rem auto;
}
#Review .slideItem .box .over .img img{
    height:100%;
}
#Review .slideItem .box .over strong{
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    position: absolute;
    bottom:2.22rem;
    left:50%;
    transform: translateX(-50%);
}
#Review .slideItem .box .over .pack{
    display: block;
    color:#1048A0;
    position: absolute;
    bottom:1.2rem;
    left:50%;
    transform: translateX(-50%);
    width:100%;
}

.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}
.slick-slide > div { height: 100%; }

div.slick-active:nth-of-type(1) {
    opacity: 20% !important;
}



@media screen and (min-width: 1300px) {

    #Review .slideItem{
        padding:0 1.5rem;
        box-sizing: border-box;
        height:100%;
    }
    #Review .slideItem .box{
        height:100%;
        background: rgba(16, 72, 160, 0.1);
        border-radius: 25px;
        padding:3rem  3rem  11rem  3rem ;
        box-sizing: border-box;
        position: relative;
        text-align: center;
    }
    #Review .h1{
        font-size: 1.2rem;
        letter-spacing: 2px;
        color:#1048A0;
        font-weight: 400;
        text-align: center;
        margin-bottom: 1.5rem;
    }
    #Review .h2{
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 4rem;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {

    #Review .slideItem{
        padding:0 0.75rem;
        box-sizing: border-box;
        height:100%;
    }
    #Review .slideItem .box{
        height:100%;
        background: rgba(16, 72, 160, 0.1);
        border-radius: 25px;
        padding:2rem  2rem  11rem 2rem ;
        box-sizing: border-box;
        position: relative;
        text-align: center;
    }
    #Review .h1{
        font-size: 1.2rem;
        letter-spacing: 2px;
        color:#1048A0;
        font-weight: 400;
        text-align: center;
        margin-bottom: 1.5rem;
    }
    #Review .h2{
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 4rem;
    }
}

@media screen and (max-width: 900px){
    #Review .slideItem{
        padding:0 0.75rem;
        box-sizing: border-box;
        height:100%;
    }
    #Review .slideItem .box{
        height:100%;
        background: rgba(16, 72, 160, 0.1);
        border-radius: 25px;
        padding:2rem  2rem  11rem  2rem ;
        box-sizing: border-box;
        position: relative;
        text-align: center;
    }
    #Review .h1{
        font-size: 1.2rem;
        letter-spacing: 2px;
        color:#1048A0;
        font-weight: 400;
        text-align: center;
        margin: 0 1rem 1.5rem 1rem;
    }
    #Review .h2{
        font-size: 1.8rem;
        text-align: center;
        margin: 0 1rem 4rem 1rem;
    }
}