#Step {
    background: rgba(16, 72, 160, 0.1);
    width:100%;
    padding:4rem 0;
    box-sizing: border-box;
    min-height: 10rem;
}

#Step .flex{
    display: flex;
    justify-content: space-between;
}

#Step .flex .box h2{
    font-size: 2.8rem;
}
#Step .flex .box span{
    font-size: 1.3rem;
    font-weight: 600;
}
#Step .flex .box p{
    font-size: 0.9rem;
    margin-top: 0.5rem;
}


@media screen and (min-width: 1300px) {
    #Step .flex .box{
        width:calc(100%/3 - 6rem);
    }
    #Step .flex .box div img{
        height:100%;
    }
    #Step .flex .box div{
        margin-top: 2rem;
        height:10rem;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    #Step .flex .box{
        width:calc(100%/3 - 2rem);
    }
    #Step .flex .box div img{
        width:100%;
        max-height: 10rem;
    }
    #Step .flex .box:nth-child(1) div img{
        width:70%;
    }
    #Step .flex .box div{
        margin-top: 2rem;
        height:10rem;
        text-align: center;
    }
}

@media screen and (max-width: 900px){
    #Step .flex{
        flex-wrap: wrap;
    }
    #Step .flex .box{
        width:calc(100%/1);
        display: inline-block;
        padding:0 3rem 3rem 3rem;
        box-sizing: border-box;
    }
    #Step .flex .box div img{
        width:100%;
        max-height: 10rem;
    }
    #Step .flex .box:nth-child(1) div img{
        width:70%;
    }
    #Step .flex .box div{
        margin-top: 2rem;
        height:10rem;
        text-align: center;
    }
}