@media screen and (min-width: 1300px) {
    #scroll .scrolltotop {
        position: fixed;
        bottom: 8em;
        right: 5em;
        transform: translate(50%, 50%);
        opacity: 0;
        text-align: center;
        transition: all 0.3s ease-in-out;
        z-index: 150;
        padding: 0.6em;
        background-color: #1048A0;
        border: 2px solid #F4F7FB;
    }

    #scroll .scrolltotop:hover {

    }


    #scroll .scrolltotop i {
        font-size: 1.1em;
        color:#F4F7FB;
        transition: all 0.3s ease-in-out;
    }
    #scroll .scrolltotop:hover i {
        font-size: 1.4em;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    #scroll .scrolltotop {
        position: fixed;
        bottom: 5em;
        right: 5em;
        transform: translate(50%, 50%);
        opacity: 0;
        text-align: center;
        transition: all 0.3s ease-in-out;
        z-index: 150;
        padding: 0.6em;
        background-color: #1048A0;
        border: 2px solid #F4F7FB;
    }

    #scroll .scrolltotop:hover {

    }


    #scroll .scrolltotop i {
        font-size: 1.2em;
        color:#F4F7FB;
        transition: all 0.3s ease-in-out;
    }
    #scroll .scrolltotop:hover i {
        font-size: 1.5em;
    }
}

@media screen and (max-width: 900px){
    #scroll .scrolltotop {
        position: fixed;
        bottom: 5em;
        right: 3em;
        transform: translate(50%, 50%);
        opacity: 0;
        text-align: center;
        transition: all 0.3s ease-in-out;
        z-index: 150;
        padding: 0.6em;
        background-color: #1048A0;
        border: 2px solid #F4F7FB;
    }

    #scroll .scrolltotop i {
        font-size: 1.1em;
        color:#F4F7FB;
        transition: all 0.3s ease-in-out;
    }
    #scroll .scrolltotop:hover i {
        font-size: 1.4em;
    }
}


#scroll .show {
    opacity: 100;
    cursor: pointer;
}
