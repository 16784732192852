

#About .left .head{

}
#About .left .head div{
    background:#1048A0;
    height:1px;
    width:3rem;
    display: inline-block;
    vertical-align: middle;
    margin-right:1rem;
}
#About .left .head h1{
    font-size: 1.2rem;
    letter-spacing: 2px;
    color:#1048A0;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
}
#About .left h2{
    font-size: 2rem;
    margin-top: 2rem;
}
#About .left p{
    margin-top: 1rem;
}


#About .right img{
    height:100%;
}

#About .bottom{
    margin-top: 2rem;
}


#About .bottom .box:nth-child(1){
    border-left: 0;
}
#About .bottom .box .img{
    height:4.5rem;

}
#About .bottom .box .img img{
    height:100%;
}
#About .bottom .box span{
    font-size: 1.2rem;
    font-weight: 600;
    display: block;

    margin: 0.5rem 0;
}
#About .bottom .box p{
    font-size: 0.9rem;
}
@media screen and (min-width: 1300px) {
    #About{
        width: 1250px;
        margin: 0 auto;
        padding: 5rem 0;
        box-sizing: border-box;
    }

    #About .right{
        width:calc(100%/2);
        display: inline-block;
        vertical-align: top;
        min-height: 20rem;
        padding-left: 4rem;
        box-sizing: border-box;
    }
    #About .left{
        width:calc(100%/2);
        display: inline-block;
        vertical-align: top;
        padding-right: 4rem;
        box-sizing: border-box;
    }
    #About .bottom .box{
        width:calc(100%/4);
        display: inline-block;
        vertical-align: top;
        padding:0 3rem;
        box-sizing: border-box;
        border-left: 1px solid #686868;
    }
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    #About{
        width:100%;
        margin: 0 auto;
        padding: 5rem 1rem;
        box-sizing: border-box;
    }

    #About .right{
        width:calc(100%/2);
        display: inline-block;
        vertical-align: top;
        height: 15rem;
        padding-left: 2rem;
        box-sizing: border-box;
    }
    #About .left{
        width:calc(100%/2);
        display: inline-block;
        vertical-align: top;
        padding-right: 2rem;
        box-sizing: border-box;
    }
    #About .bottom .box{
        width:calc(100%/4);
        display: inline-block;
        vertical-align: top;
        padding:0 3rem;
        box-sizing: border-box;
        border-left: 1px solid #686868;
    }
}

@media screen and (max-width: 900px){
    #About{
        width:100%;
        margin: 0 auto;
        padding: 5rem 1rem;
        box-sizing: border-box;
    }

    #About .right{
        width:calc(100%);
        display: inline-block;
        vertical-align: top;
        height: 15rem;
        box-sizing: border-box;
        margin:3rem 0;
    }
    #About .left{
        width:calc(100%);
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;

    }
    #About .bottom .box{
        width:calc(100%/2);
        display: inline-block;
        vertical-align: top;
        padding:0 1rem;
        box-sizing: border-box;
        margin-bottom: 2rem;
    }
}